<template>
  <div class="page-regist info-box">
    <p class="title-box">游客信息</p>
    <div class="page-form">
      <van-cell-group >
        <van-field label="姓名" v-model="info.name" placeholder="请输入您的姓名" maxlength="20"/>
        <cus-idtype-field label="证件类型" v-model="info.ID_type"/>
        <van-field label="证件号" v-model="info.ID_num" placeholder="请填写证件号码" maxlength="18"/>
        <van-field label="手机号码" v-model="info.phone" placeholder="请填写11位手机号" maxlength="11"/>
        <cus-area-field label="现住地址" v-model="info.area" placeholder="请选择现住地址"/>
        <van-field v-model="info.address" placeholder="请输入详细地址"/>
        <!--<van-checkbox v-model="info.isAgree" class="aaa">-->
          <!--<img slot="icon" v-if="info.isAgree" src="@/assets/images/cart-input3.png">-->
          <!--<img slot="icon" v-else src="@/assets/images/cart-input2.png">-->
          <!--<p>已阅读并同意《用户协议》</p>-->
        <!--</van-checkbox>-->
      </van-cell-group>
      <div class="page-buttons">
        <van-button type="primary" @click="doSave" :loading="isSubmiting" :disabled="isSubmiting" round block>下一步</van-button>
      </div>
    </div>
  </div>
</template>

<script>
  import {isCardNo,isPhoneNo} from "../../libs/kit";
  import {PUBLIC} from '@/libs/const'
  import {Icon, NavBar, DateField, SexField, PickerField} from '_c';
  import {Field, Uploader, SwitchCell, Panel, Cell, CellGroup, Button,Checkbox } from 'vant';
  import config from '@/config';

export default{
  name: 'Regist',
  components: {
    [Icon.name]: Icon,
    [NavBar.name]: NavBar,
    [DateField.name]: DateField,
    [SexField.name]: SexField,
    [PickerField.name]: PickerField,
    [Field.name]: Field,
    [Uploader.name]: Uploader,
    [SwitchCell.name]: SwitchCell,
    [Panel.name]: Panel,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Button.name]: Button,
    [Checkbox.name]: Checkbox,
    [config.name]: config,
  },
  data(){
    return {
      consts: PUBLIC,
      isSubmiting: false,
      info: {
        id: 0,
        name: '',
        area: [],
        province_code: '',
        city_code: '',
        county_code: '',
        traffic: 0,
        ID_type: 1,
        ID_num: '',
        phone: '',
        isAgree: true,
        address:'',
        carInfo:''
      },
      guardians: [],
      orderId:'',
      userId:''
    }
  },
  created() {
    this.userId = this.$store.state.userId;
    this.info.carInfo = decodeURI(this.$route.query.carInfo);
    this.info.traffic = this.$route.query.traffic;
    this.findUserInfo()
  },
  computed: {
      scenicId(){
          return this.$store.state.scenicId;
      },
      openId(){
          return this.$store.state.openId;
      },
      token(){
          return this.$store.state.token;
      }
  },
  methods: {
    //获取用户信息
    findUserInfo(){
      let data = {};
        data["userId"] = this.userId;
        this.$post('/tourist/getUserInfo', data).then(res => {
            if (res.flag === 0 && res.object != null) {
                let info = res.object;
                this.info.name = info.name;
                this.info.ID_num = info.ID_num;
                this.info.ID_type = info.ID_type;
                this.info.address = info.address;
                this.info.create_time = info.create_time;
                this.info.area = [
                    {code:info.province_code,name:info.province},
                    {code:info.city_code,name:info.city},
                    {code:info.county_code,name:info.county}
                ]
                this.info.id = info.id;
                this.info.phone = info.phone;
                this.info.scenicTitle = info.scenicTitle;
                this.info.create_time = info.create_time;
            }
        })
    },
    doSave() {
      if (this.info.name.length == 0) {
        this.$toast('请填写姓名');
        return;
      } else if (this.info.ID_type.length == 0) {
        this.$toast('请选择证件类型');
        return;
      } else if (this.info.ID_num.length == 0) {
        this.$toast('请填写证件号');
        return;
      } else if (!isCardNo(this.info.ID_num) && this.info.ID_type ==1){
        this.$toast('请填写正确的身份证号')
        return
      } else if (this.info.phone.length == 0) {
        this.$toast('请填写手机号');
        return;
      } else if (!isPhoneNo(this.info.phone) ) {
        this.$toast('请填写正确的手机号');
        return;
      } else if (this.info.area.length == 0 ) {
        this.$toast('请选择现住地址');
        return;
      }
      // else if (this.info.address.length == 0) {
      //   this.$toast('请输入详细地址');
      //   return;
      // }
      let data = {}
      data["name"] = this.info.name;
      data["ID_type"] = this.info.ID_type;
      data["phone"] = this.info.phone;
      data["ID_num"] = this.info.ID_num;
      data["province_code"] = this.info.area[0].code;
      data["city_code"] = this.info.area[1].code;
      data["county_code"] = this.info.area[2].code;
      data["address"] = this.info.address;
      data["traffic"] = this.info.traffic;
      data["openId"] = this.$store.state.openId;
      data["scenicId"] = this.$store.state.scenicId;
      data["userId"] = this.userId;
      data["carInfo"] = this.info.carInfo;
      this.guardians.forEach((item, index) => {
        data[`guardian${index}_relation`] = item.relation;
        data[`guardian${index}_name`] = item.name;
        data[`guardian${index}_idcard`] = item.idcard;
        data[`guardian${index}_address`] = item.address;
        data[`guardian${index}_tel`] = item.tel;
        data[`guardian${index}_work_unit`] = item.work_unit;
      })
      this.$post('/tourist/doSaveUser', data)
              .then(res => {
                if (res.flag == 0) {
                  if (res.object.ticketShow) {
                    this.$router.replace({path: '/ticket' + "/" + this.userId + "/" + this.$store.state.scenicId+"?carInfo="+this.info.carInfo+"&traffic="+this.info.traffic});
                  } else {
                    this.$router.replace({path: '/tourist/success/' + this.userId + "/" + this.$store.state.scenicId+"?"});
                  }
                } else if (res.flag == 12) {
                  this.$toast(res.message);
                } else {
                  this.$toast('系统错误，稍后再试');
                }
              })
    }
  }
}
</script>

<style lang='scss' scoped>
.page-form{
  padding: 0 16px;
  margin-top: 4vw;
    p{
        color: red;
    }
}
.van-cell-group{
  background-color: transparent;
  overflow: hidden;
  border-radius: 8px;
}
.page-guardian-add-btn-small{
  color: #1989fa;
  float: right;
  &::after{
    content: ' ';
    clear: both;
  }
}
.page-guardian-add-btn-big{
  width: 100%!important;
  height: 150px!important;
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  box-sizing: border-box;
  background-color: #fff;
  border: 1px dashed #ebedf0;
  border-radius: 4px;
}
.page-guardian-item{
  .van-cell.van-panel__header{
    padding: 0;
  }
}
.page-guardian-item-gap{
  background-color:#f7f8fa;
  width:100vw;
  height:5px;
}
.page-guardian-btn-wrapper{
  padding: 8px;
}
.page-guardian-btn-wrapper::after{
  content: '';
}
.page-buttons{
  padding: 20px 4px;
}
</style>
<style lang="scss">
.page-idcard-photo,.page-household-register-photo{
  display: flex;
  padding-top: 8px;
  margin-top: -8px;
  .van-uploader__preview-image,.van-uploader__upload{
    width: 100%!important;
    height: 150px!important;
    margin: 0;
  }
  .van-uploader__input{
    height: 100%;
    width: 100%;
  }
}
.page-idcard-photo{
  .van-uploader{
    width: 100vw;
  }
}
.page-household-register-photo{
  .van-uploader{
    width: 50vw;
  }
}
  .aaa img{
    width: 5vw;
  }
  .info-box {
    .van-cell{
      padding: 0 16px;
      height: 16vw;
      display: flex;
      align-items: center;
      font-size: 4vw;
    }
  }
</style>
